import React, { useState } from 'react';
import styled from 'styled-components';
import { Accordion, AccordionPanel, Box, ResponsiveContext, Text } from 'grommet';
import { CurrentsCard, MediaDownloadCard } from '../../index';
import { RichText } from 'prismic-reactjs';

const StyledAccordionPanel = styled(AccordionPanel)`
  ${({ active }) =>
    active && !window.location.pathname.includes('/better-together') ? 'background: #C4EBF6;' : ''}
  position: relative;

  ${({ faq }) => (!faq ? 'padding: 0 1%;' : 'padding: 0 8%;')}

  h4 {
    color: #272727 !important;
    font-family: Roboto, sans-serif;
    font-size: 18px;
    font-weight: bold;
    line-height: 20px;
    margin: 20px 0;
    text-transform: none;
  }

  svg {
    color: #272727 !important;
  }
`;

export default function AccordionBlock({ issues, Link, htmlSerializer, faq }) {
  const [activeIndex, setActiveIndex] = useState(!faq ? [0] : [-1]);
  return (
    <ResponsiveContext.Consumer>
      {(sizeResponsive) => {
        const mobile =
          sizeResponsive === 'small'
            ? { vertical: 'large', horizontal: 'medium' }
            : { vertical: 'large', horizontal: 'xlarge' };
        return (
          <>
            {issues ? (
              <Accordion
                border="top"
                activeIndex={activeIndex}
                onActive={(index) => setActiveIndex(index)}
                multiple
              >
                {issues.map((issue, index) => {
                  const active = activeIndex.includes(index);
                  return (
                    <StyledAccordionPanel
                      margin="auto"
                      label={issue.panel}
                      active={active}
                      onClick={() => {
                        window.dataLayer.push({
                          event: 'GAEvent',
                          eventCategory: 'Currents Interaction',
                          eventAction: 'Click',
                          eventLabel: issue.panel,
                        });
                      }}
                    >
                      <Box margin="auto" as="section" width="1440px" pad={mobile}>
                        <Box wrap direction="row-responsive" justify="left">
                          {issue.download && <MediaDownloadCard {...issue.download} />}
                          {issue.articles.map((article) => {
                            return (
                              <CurrentsCard
                                Link={Link}
                                currents={article}
                                htmlSerializer={htmlSerializer}
                              />
                            );
                          })}
                        </Box>
                      </Box>
                    </StyledAccordionPanel>
                  );
                })}
              </Accordion>
            ) : (
              <Accordion
                margin={{ top: '30px' }}
                activeIndex={activeIndex}
                onActive={(index) => setActiveIndex(index)}
                multiple
              >
                {faq.map((faq, index) => {
                  const active = activeIndex.includes(index);
                  return (
                    <Box width="1440px" margin={{ top: '30px', left: 'auto', right: 'auto' }}>
                      <StyledAccordionPanel
                        margin="auto"
                        label={faq.faq_header}
                        active={active}
                        onClick={() => {
                          window.dataLayer.push({
                            event: 'GAEvent',
                            eventCategory: 'Currents Interaction',
                            eventAction: 'Click',
                            eventLabel: faq.faq_header,
                          });
                        }}
                      >
                        <Box margin="auto" as="section" width="1440px" pad={mobile}>
                          <Box wrap direction="row-responsive" justify="center">
                            <Text size={sizeResponsive}>
                              <RichText render={faq.faq_copy} htmlSerializer={htmlSerializer} />
                            </Text>
                          </Box>
                        </Box>
                      </StyledAccordionPanel>
                    </Box>
                  );
                })}
              </Accordion>
            )}
          </>
        );
      }}
    </ResponsiveContext.Consumer>
  );
}
