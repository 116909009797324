import React, { useEffect, useRef } from 'react';
import { Anchor, Box, Text, Heading } from 'grommet';
import { RichText } from 'prismic-reactjs';
import { Link } from 'gatsby';
import GatsbyButton from '../GatsbyButton';

function copyPadding(size, reverse) {
  switch (size) {
    case 'small':
      return 'none';
    case 'medium':
      return reverse ? { right: 'large' } : { left: 'large' };
    default:
      return reverse ? { right: 'xlarge' } : { left: 'xlarge' };
  }
}

function imgPadding(size, reverse) {
  switch (size) {
    case 'small':
    case 'medium':
      return 'none';
    default:
      return reverse ? { left: 'small' } : { right: 'small' };
  }
}

function mainPadding(size) {
  switch (size) {
    case 'small':
      return 'medium';
    case 'medium':
      return 'large';
    default:
      return 'xlarge';
  }
}

export default function({ size, cardInformation, htmlSerializer }) {
  const scrollRef = useRef(null);
  const isSmall = size === 'small';

  const executeScroll = () => scrollRef.current.scrollIntoView();

  useEffect(() => {
    if (window.location.href.includes('#esg_report')) {
      executeScroll();
    }
  }, []);

  return (
    <Box pad={{ horizontal: mainPadding(size) }}>
      <Box width="1440px" margin="auto">
        {cardInformation.map(({ id, image, title, description, contactEmail }, index) => {
          const reverse = index % 2 !== 0;
          const direction = reverse ? 'row-reverse' : 'row';
          const imgUrl = isSmall && image.mobile ? image.mobile.url : image.url;
          return (
            <Box key={id} direction={isSmall ? 'column' : direction} margin={{ bottom: 'xlarge' }}>
              <Box basis={isSmall ? 'full' : '1/2'} pad={imgPadding(size, reverse)}>
                <img src={imgUrl} alt={image.alt || ''} width="100%" />
              </Box>
              <Box basis="1/2" pad={copyPadding(size, reverse)}>
                <Heading level="3" margin={isSmall ? { top: 'large', bottom: 'none' } : 'none'}>
                  {title}
                </Heading>
                <Text size={size}>
                  <RichText render={description} htmlSerializer={htmlSerializer} />
                </Text>
                {contactEmail && (
                  <Text size={size}>
                    <br />
                    For questions or more information, email
                    <br />
                    <Anchor label={contactEmail} href={`mailto:${contactEmail}`} />
                  </Text>
                )}
                {window.location.pathname.includes('/careers') &&
                  title.includes('POWER Foundation') && (
                    <Box>
                      <GatsbyButton
                        Link={Link}
                        href="/POWER Foundation Impact Report.pdf"
                        document
                        label="Click here to view the Foundation Impact Report"
                        margin={{ top: isSmall ? 'medium' : 'large' }}
                        style={{ textAlign: 'center' }}
                      />
                    </Box>
                  )}
                {window.location.pathname.includes('/careers') && title.includes('ESG') && (
                  <Box>
                    <GatsbyButton
                      Link={Link}
                      href="/esg_report.pdf"
                      document
                      label="Click here to view ESG Report"
                      margin={{ top: isSmall ? 'medium' : 'large' }}
                      style={{ textAlign: 'center' }}
                    />
                  </Box>
                )}
                {window.location.pathname.includes('/better-together') && (
                  <Box>
                    <GatsbyButton
                      Link={Link}
                      href={cardInformation[0].document.url}
                      document
                      label="Learn More About WSP"
                      margin={{ top: isSmall ? 'medium' : 'large' }}
                      style={{ textAlign: 'center' }}
                    />
                  </Box>
                )}
              </Box>
              {document && <div ref={scrollRef} />}
            </Box>
          );
        })}
      </Box>
    </Box>
  );
}
