import React from 'react';
import { Box, Heading, ResponsiveContext } from 'grommet';
import GatsbyButton from '../GatsbyButton';
import { mainPadding } from '../../utils/layout';

export default function AboutCta({
  Link,
  ctaHeadingOne,
  ctaToOne,
  ctaButtonOne,
  ctaHeadingTwo,
  ctaToTwo,
  ctaButtonTwo,
}) {
  return (
    <ResponsiveContext.Consumer>
      {(sizeResponsive) => {
        const align = sizeResponsive === 'small' ? 'center' : 'start';
        return (
          <Box
            as="section"
            pad={
              window.location.pathname.includes('/better-together')
                ? 'small'
                : mainPadding(sizeResponsive)
            }
            align="center"
          >
            <Box width="1440px" gap="large" direction="row-responsive">
              <Box pad="large" background="neutral-4" basis="1/2">
                <Heading size={sizeResponsive} level={4} margin="none" color="dark-0">
                  {ctaHeadingOne}
                </Heading>
                <Box margin={{ top: '30px' }} align={align}>
                  <GatsbyButton Link={Link} primary href={ctaToOne} label={ctaButtonOne} />
                </Box>
              </Box>
              <Box pad="large" background="neutral-4" basis="1/2">
                <Heading size={sizeResponsive} level={4} margin="none" color="dark-0">
                  {ctaHeadingTwo}
                </Heading>
                <Box margin={{ top: '30px' }} align={align}>
                  <GatsbyButton Link={Link} primary href={ctaToTwo} label={ctaButtonTwo} />
                </Box>
              </Box>
            </Box>
          </Box>
        );
      }}
    </ResponsiveContext.Consumer>
  );
}
