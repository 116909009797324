import React from 'react';
import { Box, Heading, ResponsiveContext } from 'grommet';
import styled from 'styled-components';
import { EyebrowText } from '../../index';

const DropShadowBox = styled(Box)`
  box-shadow: 10px 10px 15px 0 rgba(0, 0, 0, 0.15);
  position: relative;
`;

const StyledImg = styled(Box)`
  width: 110px;
  height: 110px;
  margin-top: -80px;

  @media screen and (min-width: 769px) {
    width: 165px;
    height: 165px;
    margin-top: -130px;
  }
`;

const Divider = styled.div`
  height: 3px;
  width: 29px;
  background-color: #cb361d;
  margin: 15px 0 30px 0;
`;

export default function AboutCard({ authorImage, authorName, authorQuote, authorTitle }) {
  return (
    <ResponsiveContext.Consumer>
      {(sizeResponsive) => {
        const cardMargin =
          sizeResponsive === 'small' ? { horizontal: '0px' } : { horizontal: 'large' };
        const nameAndTitle = `${authorName}, ${authorTitle}`;
        return (
          <Box justify="center" height="100%" margin={cardMargin}>
            <DropShadowBox align="center" background="white" pad="large">
              <Box>
                <StyledImg
                  alignSelf="center"
                  width={sizeResponsive === 'small' ? '110px' : '165px'}
                  height={sizeResponsive === 'small' ? '110px' : '165px'}
                  round="full"
                  flex={false}
                  background={{
                    repeat: 'no-repeat',
                    size: 'cover',
                    image: `url(${authorImage.url})`,
                  }}
                />
                <Box align="center" justify="center">
                  <Heading
                    margin={{ vertical: 'small' }}
                    color="dark-4"
                    level="4"
                    textAlign="center"
                    size={sizeResponsive}
                  >
                    {authorQuote}
                  </Heading>
                  {authorName && authorTitle ? (
                    <>
                      <Divider />
                      <EyebrowText size={sizeResponsive}>{nameAndTitle}</EyebrowText>
                    </>
                  ) : null}
                </Box>
              </Box>
            </DropShadowBox>
          </Box>
        );
      }}
    </ResponsiveContext.Consumer>
  );
}
